@font-face {
    font-family: 'Urbanist'; /* Give your font a name */
    src: url('./../fonts/Urbanist-VariableFont_wght.ttf') format('truetype'); /*  Safari, Android, iOS  */
    font-weight: normal; 
    font-style: normal;
  }

  @font-face {
    font-family: 'Urbanist'; /* Give your font a name */
    src: url('./../fonts/Urbanist-Italic-VariableFont_wght.ttf') format('truetype'); /*  Safari, Android, iOS  */
    font-weight: normal; 
    font-style: italic;
  }